<template>
  <section>
    <v-container>
    <v-row>
      <v-col
        cols="12"
        md="11"
        sm="10"
        offset-sm="1"
      >
        <h1 class="font-weight-thin mb-9 text-h4 text-sm-h3 text-xl-h2">
          Was wird aus unserer Zukunft?
        </h1>
        <div class="textbg font-weight-light">
        <p>
          Ein Grad Erderwärmung haben wir bereits überschritten, 
          doch Wissenschaftler schlagen zunehmend Alarm, denn das Thermometer klettert ungebremst weiter. 
        </p>
        <p>
          Politisches Gegensteuern endet zu oft nur mit Lippenbekenntnissen, 
          denn Einschränkungen kosten Wählerstimmen.
        </p>
        <h2>
          Wir sind alle gefordert!
        </h2>
        <p>
          Wenn nicht jeder von uns mit CO2-Reduzierungen etwas beiträgt, 
          werden wir die 1,5 Grad-Hürde reißen. 
        </p>
        <p>             
          Wetterextreme durch den Klimawandel spüren wir immer deutlicher. 
Aber was hemmt uns, warum begreifen wir die Gefahr nicht?
        </p>

        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        sm="3"
        offset-sm="1"
      >
        <v-img 
          max-width="200"
          max-height="300"
          src="img/Warum_titel.webp" 
          alt="Buchreihe"></v-img>
      </v-col>
      <v-col
        cols="12"
        sm="8"
      >
        <div class="textbg font-weight-light">
          <h4>Band 1 - "Warum"</h4>
          <p>
            Dieses Büchlein ist der Versuch, die Auswirkungen 
            unseres Handelns möglichst einfach zu erklären. 
            Denn es kann nur dann ein Umdenken stattfinden, 
            wenn wir die Zusammenhänge hinreichend verstehen.
            Verständnis wird automatisch unser Handeln bewirken.
          </p>
          <p>
            Dieses Büchlein ist für alle, denen die Zukunft unserer Kinder 
  eben so wichtig ist wie die Eigene.
          </p>
          <p>
            120 Seiten für 9,50 Euro (plus 1,50 Euro Versand) 
  erhalten Sie über folgende Mailadresse: <a class="mailto" href="mailto:frank@schroepfer-net.de">frank@schroepfer-net.de</a> 
          </p>
        </div>
      </v-col>

    </v-row>
    <v-row>
      <v-col    
        cols="12"
        sm="3"
        offset-sm="1"
      >
        <v-img
          max-width="200"
          max-height="300"
          src="img/Titel_02_01.webp" 
          alt="Band 2 - Die Methan Falle"></v-img>
      </v-col>
      
      <v-col    
        cols="12"
        sm="8"
      >
        <div class="textbg text-justify font-weight-light">
          <h4>Band 2 - "Die Methan Falle"</h4>
          <p>
            Anfang Juli 2019 wurden in Anchorage, der größten Stadt Alaskas, über 32 Grad
Celsius gemessen. Während dieser Hitzewelle wüteten Waldbrände und der
Himmel verdunkelte sich. Zum ersten Mal seit Beginn der Wetteraufzeichnungen
war diese Temperatur gemessen worden. Es war der heißeste Juli in der
Geschichte dieser Stadt. Aber auch auf Grönland waren die Temperaturen Ende
Juli 2019 um etwa ZWÖLF Grad höher als für diese Zeit üblich.
          </p>
          <p>
Während der CO2-Ausstoß aber auch die Naturzerstörung und das Artensterben
die Themen in meinem ersten Band waren, werden mittlerweile 17 große Umweltprobleme
benannt, die als so genannte Kippelemente gelten.
          </p>
          <p>
            
Die Lage scheint daher immer auswegsloser zu werden. Doch was folgt, wenn wir die
CO2 - REDUZIERUNG nicht erreichen? Der „New green Deal“ der EU scheint auf
dem Weg zu sein, doch das zeitlich weit nach hinten gesteckte Ziel 2050 spricht
Bände. Im Moment steuert die Welt mit den in Paris eingereichten Klimaplänen
auf eine Durchschnitttemperatur von 3+ Grad zu. Die 1,5 Grad Latte scheint
bereits gerissen zu sein, doch das nächste Kippelement entfaltet bereits ungebremmst
seine Wirkung. Es hat das Potential, die Durchschnittstemperatur auf
4 Grad und mehr anzuheben und es kommt gerade erst in Fahrt. Die Rede ist von
Methan, das in gewaltigen Mengen in den Permafrostböden schlummert
und aufzutauen droht.
          </p>
          <p>
            120 Seiten für 9,50 Euro (plus 1,50 Euro Versand) erhalten Sie über folgende Mailadresse: <a href="mailto:frank@schroepfer-net.de">frank@schroepfer-net.de</a> 
          </p>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col    
        cols="12"
        sm="3"
        offset-sm="1"
      >
        <v-img
          max-width="200"
          max-height="300"
          src="img/Titel_03_01.webp" 
          alt="Band 3 - Gedanken auf dem Weg vom Spessart ins Fichtelgebirge"></v-img>
      </v-col>
      
      <v-col    
        cols="12"
        sm="8"
      >
        <div class="textbg text-justify font-weight-light">
          <h4>Band 3 - "Gedanken auf dem Weg vom Spessart ins Fichtelgebirge"</h4>
          <p>
            Beim Blick nach vorne sehe ich viel Grünes. Es leuchtet in allen
Farbnuancen und unzählige Formen fordern meinen Geist. 
Der gute alte Spessart, mit seinen Buchen- und Eichenwäldern,
der hat schon was. Anschließend lief ich durch die Rhön, dem Land 
der offenen Fernen bis hinüber zu den Hassbergen. Schließlich
kam ich an den  Main. Steil hinauf führte mich ein Pfad auf den 
Staffelberg. Von dort oben aus begeisterten mich weite Ausblicke 
auf den schönen Main, die ich anschließend auf dem Mainwander-
weg nach Kulmbach immer wieder genießen durfte. Auch im Fichtel-
gebirge konnten sich meine Augen kaum satt sehen. Auf die
bekannten Steinmonumente freue ich mich jedes Mal, wenn ich dort 
unterwegs bin. So auch im Juli 2020, als ich auf dem fränkischen 
Gebirgsweg unterwegs war und dabei den Ochsenkopf bestieg.
          </p>
          <p>
            Bei jedem Schritt einer Wanderung setzen wir beständig Fuß um 
Fuß auf den Boden. Dabei machen wir uns kaum Gedanken, was 
dieser so alles ertragen muss. Doch dieses Mal wollte ich es anders
angehen, ihm mehr Beachtung schenken, denn eigentlich hat er 
nicht viel zu lachen. So quälen wir ihn zunehmend mit Stickstoff und 
Phosphat, aber auch mit Pestiziten und ähnlichen Giften.
          </p>
          <p>
Daran wurde ich auf meiner 
Wanderung immer wieder erinnert, 
vor allem dann, wenn ich auf grüne 
Kreuze traf. Landwirte wollten damit 
eindrucksvoll zeigen, wem hier 
Unrecht geschieht. Doch erinnern 
sie uns nicht auch daran, dass das 
Leben dort so gut wie tot ist?
          </p>
          <p>
            140 Seiten für 9,50 Euro (plus 1,50 Euro Versand) erhalten Sie über folgende Mailadresse: <a href="mailto:frank@schroepfer-net.de">frank@schroepfer-net.de</a> 
          </p>
        </div>
      </v-col>
    </v-row>
    </v-container>
  </section>
</template>

<script>

export default {
  name: 'Fotografie',
  components: {
    // NavCtrl: () => import('@/components/core/NavCtrl')
  },
  data() {
    return {
    }
  },
  computed: {
    routeMeta: function () {
      return this.$route.meta
    }
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
  .mailto{
    font-weight: bold;
  }
</style>
