<template>
  <v-app class="orange lighten-3" v-scroll="onScroll">
    <v-main>
      <v-card dark tile flat height="100%" 
      style="padding-bottom: 80px; background-attachment: fixed;" 
      :img="imgUrl"
      >
        <v-app-bar
          flat
          app
          color="orange lighten-2"
          dark
          dense
        >
          <!-- <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon> -->
          <v-spacer></v-spacer>
          <!-- {{scrollInvoked}} -->
          <!-- <router-link to="/service/impressum">
            <v-btn
              class="ma-2"
              text
              color="white"
            >
              Impressum
            </v-btn>
          </router-link> -->

          <!-- <v-btn
            color="white"
            icon
          >
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn> -->
          <information />
        </v-app-bar>
        <v-navigation-drawer
          v-model="drawer"
          absolute
          temporary
          light
        >
          <v-list
            nav
            dense
          >
            <template v-for="item in items">
              <v-list-item 
                v-if="item.children.length <= 0"
                :value="item.active" 
                :key="item.name"
                :to="item.path"
                link >
                <v-list-item-title v-text="item.name"></v-list-item-title>
              </v-list-item>
              <v-list-group
                v-else
                :value="item.active"
                no-action
                
                :key="item.name"
              >
                <template v-slot:activator>
                  <v-list-item-content>
                    <v-list-item-title v-text="item.name"></v-list-item-title>
                  </v-list-item-content>
                </template>
                <v-list-item 
                  v-for="subitem in item.children" 
                  :value="subitem.active" 
                  :key="subitem.name"
                  link 
                  :to="item.path + '/' +subitem.path">
                  <v-list-item-title v-text="subitem.name"></v-list-item-title>
                </v-list-item>
              </v-list-group>
            </template>
          </v-list>
        </v-navigation-drawer>
        <!-- {{clientHeight}}
        {{clientHeight - 200 }} -->
        <!-- <div class="pa-4" :style="'max-height: '+ clientHeight - 400 + 'px; overflow-y: auto;'"  -->
        <!-- <div v-scroll.self="onScroll" class="pa-4 px-10 pt-10"
          v-touch="{
            left: () => swipe('right'),
            right: () => swipe('left'),

          }"> -->
        <div class="pa-4 px-10 pt-10"
          v-touch="{
            left: () => swipe('right'),
            right: () => swipe('left'),
            up: () => swipe('down'),
            down: () => swipe('up')
          }">
        
          <transition name="fade" mode="out-in">
            <router-view :key="$route.fullPath"></router-view>
          </transition>
        
        </div>
        <div v-if="routeMeta.up" class="link link-up">
          <nav-ctrl direction="up" :text1="routeMeta.up.text1" :text2="routeMeta.up.text2" :target="routeMeta.up.to" />
        </div>
        <div v-if="routeMeta.down" class="link link-down">
          <nav-ctrl direction="down" :text1="routeMeta.down.text1" :text2="routeMeta.down.text2" :target="routeMeta.down.to" />
        </div>
        <div v-if="routeMeta.left" class="link link-left">
          <nav-ctrl direction="left" :text1="routeMeta.left.text1" :text2="routeMeta.left.text2" :target="routeMeta.left.to" />
        </div>
        <div v-if="routeMeta.right" class="link link-right">
          <nav-ctrl direction="right" :text1="routeMeta.right.text1" :text2="routeMeta.right.text2" :target="routeMeta.right.to" />
        </div>
      </v-card>
      <Footer />
    </v-main>
  </v-app>
</template>

<script>
// import Information from './components/Information.vue';
// import Impressum from './views/Impressum.vue';
import Footer from './components/core/Footer.vue';

export default {
  name: 'App',
  components: {
    Information: () => import('@/components/InformationDialog'),
    NavCtrl: () => import('@/components/core/NavCtrl'),
    Footer
  },
  data: () => ({
    page: 1,
    drawer: false,
    group: null,
    items: [],
    clientWidth: document.documentElement.clientWidth,
    clientHeight: document.documentElement.clientHeight,
    scrollInvoked: 0,
    scrollInvokedTemp: 0,
  }),

  watch: {
    group () {
      this.drawer = false
    },
  },
  computed: {
    routeMeta: function () {
      return this.$route.meta
    },
    imgUrl: function() {
      if (typeof this.$route.meta.bgUrl !== 'string') {
        return 'img/Naturerleben_md.webp'
      }
      if (this.clientWidth <= 600) {
        return this.$route.meta.bgUrl.replace('.','_xs.')
      } else if (this.clientWidth <= 960) {
        return this.$route.meta.bgUrl.replace('.','_sm.')
      } else if (this.clientWidth <= 1264) {
        return this.$route.meta.bgUrl.replace('.','_md.')
      }{
        return this.$route.meta.bgUrl
      }
    }
  },
  methods: {
    onScroll () {
      this.scrollInvoked++
      // console.log(this.scrollInvoked)      
    },
    swipe (direction) {
      // console.log(direction)
      if (typeof this.routeMeta[direction] !== 'undefined') {
        if (direction === 'up' || direction === 'down') {
          setTimeout(() => {
            if (this.scrollInvokedTemp === this.scrollInvoked || this.scrollInvoked === 0) {
              this.$router.push(this.routeMeta[direction].to)
              this.scrollInvoked = 0
            }
            this.scrollInvokedTemp = this.scrollInvoked

          }, 100 )
          return
        }
        this.$router.push(this.routeMeta[direction].to)
      }

    },
  },
  created() {
    this.$router.options.routes.forEach(route => {
      let children = [];

      if (typeof route.children !== 'undefined') {
        for (let index = 0; index < route.children.length; index++) {
          const element = route.children[index];
          children.push({
            name: element.name, path: element.path
          })
        }
      }
      // if (typeof route.meta.type === 'undefined') {
      //   this.items.push({
      //     name: route.name, path: route.path, children: children
      //   })
      // }
    })
  },
  mounted: function () {
    window.addEventListener('resize', () => {
      this.clientWidth = document.documentElement.clientWidth
      this.clientHeight = document.documentElement.clientHeight
      this.$store.dispatch('changeScreenWidth', document.documentElement.clientWidth)
      this.$store.dispatch('changeScreenHeight', document.documentElement.clientHight)
    })
  },
  beforeDestroy: function () {
    window.removeEventListener('resize', () => {})
  },
};
</script>

<style lang="scss">
  .bgStyle {
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
    background-position: top;
    background-attachment: fixed;
    height: 100%;
  }
  p {
    font-size: 1.1rem;
  }
  .v-parallax__image {
      top: 0;
  }
  .v-application a {
      // color: #1976d2;
      text-decoration: none;
  }
  .textbg {
    color: #FAFAFA;
    background: linear-gradient(0deg, #5f5f5f44 25%, #6e6e6e44 100%);
    // background: linear-gradient(0deg, #00000066 30%, #ffb74d44 100%);
    // border:#ffffff44 1px solid;
    border-radius: 4px; 
    padding: 1em;
    margin-bottom: 1em;
    // font-weight: 100 !important;
    // font-family: Trebuchet MS,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Tahoma,sans-serif; 
  }
  .link {
    position: absolute;
    // background-color: aquamarine;
  }
  .link-down {
    width: 100%;
    bottom: 90px;
  }
  .link-up {
    width: 100%;
    top: 10px;
  }
  .link-left {
    width: 40px;
    top: 50px;
    left: 10px;
  }
  .link-right {
    width: 40px;
    top: 50px;
    right: 10px;
  }
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.35s ease;
  }

  .fade-enter-from,
  .fade-leave-active {
    opacity: 0;
  }
</style>
