<template>
  <v-footer
    class="py-4"
    dark
    height="auto"
    width="100%"
    fixed
    color="orange lighten-2"
  >
    <v-row no-gutters>
      <v-col cols="6" class="text-left">
        <base-btn
          v-for="(item, i) in items"
          :key="i"
          :href="item.href"
          square
          target="_blank"
          class="mr-4"
        >
          <v-icon v-text="item.icon" />
        </base-btn>
      </v-col>
      <v-col cols="6" class="text-right">
        <base-btn
          square
          title="Go to top"
          @click="$vuetify.goTo(0)"
        >
          <v-icon>mdi-chevron-up</v-icon>
        </base-btn>
      </v-col>
    </v-row>
  </v-footer>
</template>

<script>
import BaseBtn from '@/components/core/Btn.vue';

  export default {
    components: {
      BaseBtn
    },
    data: () => ({
      items: [
        {
          href: 'https://www.instagram.com/frank_schroepfer/',
          icon: 'mdi-instagram'
        },
        {
          href: 'https://www.facebook.com/people/Frank-Schr%C3%B6pfer/100009321392269',
          icon: 'mdi-facebook'
        },
      ]
    })
  }
</script>
