import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import Vuetify from 'vuetify/lib'
import VueImg from 'v-img';
// import colors from 'vuetify/lib/util/colors'

Vue.use(VueImg);
Vue.use(Vuetify)

Vue.config.productionTip = false

export default new Vuetify({
  theme: {
    dark: true,
    // themes: {
    //   light: {
    //     primary: colors.red.darken1, // #E53935
    //     secondary: colors.red.lighten4, // #FFCDD2
    //     accent: colors.indigo.base, // #3F51B5
    //   },
    // },
  },
})

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
