import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    app: {
      screenWidth: 1024,
      screenHight: 800
    }
  },
  mutations: {
    changeScreenWidth (state, width) {
      // mutate state
      state.app.screenWidth = width
    },
    changeScreenHeight (state, height) {
      // mutate state
      state.app.screenWidth = height
    }
  },
  actions: {
    changeScreenWidth (context, width) {
      context.commit('changeScreenWidth', width)
    },
    changeScreenHeight (context, height) {
      context.commit('changeScreenHeight', height)
    }
  },
  modules: {
  },
  getters: {
    screenWidth: state => {
      return state.app.screenWidth
    },
    screenHight: state => {
      return state.app.screenHight
    }
  }
})
