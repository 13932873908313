import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home'

Vue.use(VueRouter)

const erleben = {
  to: 'raus',
  text1: 'Natur',
  text2: 'erleben',
};

const spueren = {
  to: 'bewahren',
  text1: 'Natur',
  text2: 'bewahren',
};


const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: { 
      bgUrl: 'img/Nachdenken.webp',
      right: spueren,
      left: erleben,
    }
  },
  {
    path: '/impressum',
    name: 'Impressum',
    component: () => import(/* webpackChunkName: "impressum" */ '../components/Impressum.vue'),
    meta: { 
      type: 'internal',
      bgUrl: 'img/Naturerleben.webp'
    }
  },
  {
    path: '/raus',
    name: 'Raus in die Natur',
    beforeEnter() {location.href = 'https://raus-indienatur.de/'}
  },
  {
    path: '/bewahren',
    name: 'Natur bewahren',
    beforeEnter() {location.href = 'https://www.derschatzvorunsererhaustuer.de/'}
  },
];

const router = new VueRouter({
  routes
})

export default router
